
import React, { Component } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import weixin from '../img/weixin.jpeg'
import axios from "axios"
import BScroll from '@better-scroll/core'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import mainicon from '../img/main.jpg'
let a = 0
export default class componentName extends Component {
  /**是否是测试链接 *///wx2de61ec075e28685
  isDebug = false;
  appid = this.isDebug ? 'wx2de61ec075e28685' : 'wx9dc526c2fae22876'; //普通浏览器测试appid（扫码登陆）
  // appid = this.isDebug ? '   ' : 'wx01254fad5fc2b79a'; //普通浏览器测试appid（扫码登陆）

  wechatBrowserAppid = 'wx01254fad5fc2b79a' //微信浏览器中使用appi直接拉起授权

  redirectURI = this.isDebug ? 'http://www.website-client-test.icesimba.com/index.html' : 'http://www.icesimba.com/index.html' //重定向回来的url
  wxState = this.isDebug ? '' : '' //透穿参数

  state = {
    listarray: [],
    list: [],
    newlist: [],
    newList1: [],
    phoneNum: "",
    loading: false,
    visible: false,
    showModalPhone: false,
    wechactlogin: '',
    phone: "",
    flag: false,
    revice: [],
    go: 0,
    settings: {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
  getQueryString(name) {

    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);

    if (r != null) return unescape(r[2]);

    return null;
  }
  showModal = (id) => {
    // sessionStorage.getItem("token")
    this.ifArr(id) && message.success("已经预约")
    // window.sessionStorage.reload()
    if (sessionStorage.getItem("serviceTokens")) {
      let data = {
        openId: sessionStorage.getItem("openId"),
        platform: 1,
        mobile: sessionStorage.getItem("phone"),
        gameId: id
      }
      let config = {
        method: 'post',
        url: 'https://website-backend.icesimba.com/api/v1/reserve',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem("serviceTokens"),
        },
        data: data
      };
      axios(config).then(res => {
      })
    }
    let data1 = {
      source: "WeChatAccount",
      platformId: this.getQueryString('code'),
      password: "",
      appId: '',
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      data1.appId = 'official-account'
    } else {
      data1.appId = 'website-c'
    }
    let config1 = {
      method: 'get',
      url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + window.sessionStorage.getItem("token")
      },
      data: data1
    };
    axios(config1)
      .then(res => {
        this.setState({
          revice: res,
        })
        console.log(res);
      })
    this.setState({
      visible: true,
      wechactlogin: id,

    });
    if (window.sessionStorage.getItem('phone')) {
      alert('预约成功')
      window.location.reload()
    }
  };
  showModal1 = () => {

    this.setState({
      visible: false,
      showModalPhone: true,
    });
  };

  yu = (itemq) => {
    sessionStorage.setItem('flag', 4)
    sessionStorage.setItem('id', itemq)
    // sessionStorage.setItem('id',this.props.location.item.id)
    this.props.history.push({
      pathname: `/yuyue/${itemq}`,
      item: itemq
    })

  }
  tiao = (itemq) => {
    if (itemq.stage === 0) {
      sessionStorage.setItem('flag', 1)
      sessionStorage.setItem('banner', itemq.contentId)
      console.log('yy1')
      this.props.history.push({
        pathname: `/yuyue/${itemq.contentId}`,
        item: itemq
      })
    } else if (itemq.stage === 1) {
      sessionStorage.setItem('flag', 2)
      sessionStorage.setItem('contentId', itemq.contentId)
      console.log('dt1')
      this.props.history.push({
        pathname: `/detail/${itemq.contentId}`,
        item: itemq
      })
    }
  }
  handleTouchStart = e => {
    this.startY = e.touches[0].clientY;
    this.startX = e.touches[0].clientX;
  };

  handleTouchMove = e => {
    this.endY = e.touches[0].clientY;
    this.endX = e.touches[0].clientX;
  }
  towecha = () => {
    let isMobile = false;
    let url = '';
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      isMobile = true;
    }
    if (isMobile) {
      var ua = navigator.userAgent.toLowerCase();
      // if(ua.match(/MicroMessenger/i)=="micromessenger") {  //微信浏览器  
      url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.wechatBrowserAppid}&redirect_uri=${this.redirectURI}&response_type=code&scope=snsapi_userinfo&state=${this.wxState}#wechat_redirect`


      window.location.href = url
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=${this.appid}&redirect_uri=${this.redirectURI}&appointmentId=${this.state.wechactlogin}&response_type=code&scope=snsapi_login&state=${this.wxState}#wechat_redirect`
      window.location.href = url
    }
  }
  changeNum = (e) => {
    this.setState({
      phoneNum: e.target.value
    })
  }
  getCode = () => {
    if (this.state.phoneNum) {
      axios.post(`https://simbasdk.icesimba.com/simba-sdk/api/v1/send-verify-code?phoneNumber=${this.state.phoneNum}`).then(res => {
        if (res) {
          message.success('验证码发送成功');
        }
      })
    } else {
      message.warning('请输入手机号！');
    }
  }
  ifArr = (id) => {
    let b = this.state.revice.length > 0 && this.state.revice.map(item => {
      if (item.gameId == id) {
        return true;
      }
      else {
        return false
      }
    })
    if (b.length) {
      return b.includes(true)
    }
  };
  _isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  }
  componentDidMount() {
    setTimeout(() => {
      new Swiper('.swiper-container', {
        loop: true, // 循环模式选项
        autoplay: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        //小圆点分页   
        //autoplay: true,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
      })
    }, 1000);
    let data1 = {
      source: "WeChatAccount",
      platformId: this.getQueryString('code'),
      password: "",
      appId: "website-c",
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      data1.appId = 'official-account'
    } else {
      data1.appId = 'website-c'
    }
    const openId1 = window.sessionStorage.getItem('openId')
    let config1 = {
      method: 'get',
      url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + window.sessionStorage.getItem("token")
        // Authorization:'123'
      },
      data: data1
    };
    axios(config1)
      .then(res => {
        this.setState({
          revice: res.data
        })
      })
    if (this.getQueryString('code')) {
      let data = {
        source: "WeChatAccount",
        platformId: this.getQueryString('code'),
        password: "",
        appId: "website-c"
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        data.appId = 'official-account'
      } else {
        data.appId = 'website-c'
      }
      let config = {
        method: 'post',
        url: 'https://simbasdk.icesimba.com/simba-sdk/api/v1/login',
        headers: {
          'Content-Type': 'application/json',
          // Authorization:'Bearer' + ' ' + window.sessionStorage.getItem("token")
        },
        data: data
      };
      axios(config)
        .then(res => {
          this.setState({
            go: res.status
          })
          if (res.status === 200) {
            let a = window.sessionStorage.getItem("token")
            this.setState({
              flag: true,
            })
            if (res.data.payload.mobile === "") {
              sessionStorage.setItem("token", res.data.accessToken)
              sessionStorage.setItem("openId", res.data.payload.openId)
              sessionStorage.setItem("serviceTokens", res.data.serviceTokens["website-backend"])
              this.setState({
                showModalPhone: true
              })
            } else {
              sessionStorage.setItem("token", res.data.accessToken)
              sessionStorage.setItem("openId", res.data.payload.openId)
              sessionStorage.setItem("phone", res.data.payload.mobile)
              sessionStorage.setItem("serviceTokens", res.data.serviceTokens["website-backend"])
            }
          }
          if (window.sessionStorage.getItem('phone')) {
            window.location.reload()
          }
          let data1 = {
            source: "WeChatAccount",
            platformId: this.getQueryString('code'),
            password: "",
            appId: "website-c",
          }
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            data1.appId = 'official-account'
          } else {
            data1.appId = 'website-c'
          }
          const openId1 = window.sessionStorage.getItem('openId')
          let config1 = {
            method: 'get',
            url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer' + ' ' + window.sessionStorage.getItem("token")
              // Authorization:'123'
            },
            data: data1
          };
          axios(config1)
            .then(res => {
              this.setState({
                revice: res
              })
            })
        })
        .catch(function (error) {
        });
    }
    setTimeout(() => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (this.handleTouchStart === this.startX && this.handleTouchMove === this.endX) {
          console.log('横滚====')
          new BScroll(this.refs.xinyuyue, {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll('.content_xibig', {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll('.conntent_big', {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll(this.refs.scroll1, {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true

          })
          new BScroll(this.refs.scroll2, {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll(this.refs.scroll3, {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll(this.refs.scroll4, {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll(this.refs.scroll5, {
            scrollX: true,
            handleTouchMove: true,
            click: true,
            handleTouchStart: true
          })
          new BScroll(this.refs.scroll6, {
            scrollX: true,

            click: true,
          })

        }
      } else {

        new BScroll('.content_xibig', {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true
        })
        new BScroll(this.refs.xinyuyue, {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true
        })
        new BScroll('.conntent_big', {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true

        })
        new BScroll(this.refs.scroll1, {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true

        })
        new BScroll(this.refs.scroll2, {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true
        })
        new BScroll(this.refs.scroll3, {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true
        })
        new BScroll(this.refs.scroll4, {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true
        })
        new BScroll(this.refs.scroll5, {
          scrollX: true,
          handleTouchMove: true,
          click: true,
          handleTouchStart: true
        })
        new BScroll(this.refs.scroll6, {
          scrollX: true,

          click: true,
        })


      }

    }, 1000)


    axios.get('https://website-backend.icesimba.com/api/v1/banners').then(res => {
      this.setState({
        listarray: res.data
      })
    })
    axios.get('https://website-backend.icesimba.com/api/v1/game-list').then(res => {
      this.setState({
        list: res.data
      })
    })
    axios.get('https://website-backend.icesimba.com/api/v1/games?status=1&stage=0').then(res => {
      this.setState({
        newlist: res.data
      })

    })
    axios.get('https://website-backend.icesimba.com/api/v1/games?status=1&stage=0').then(res => {
      this.setState({
        newlist: res.data
      })
    })
  }
  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };
    const onFinish = (values) => {
      let data = {
        openId: sessionStorage.getItem("openId"),
        source: "WeChatAccount",
        phoneNumber: values.phone,
        password: "",
        appId: "",
        payload: ""
      }
      sessionStorage.setItem("password", values.password)
      let config = {
        method: 'post',
        url: `https://simbasdk.icesimba.com/simba-sdk/api/v1/verify-bind-mobile?verifyCode=${values.password}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + ' ' + sessionStorage.getItem("token")
        },

        data: data,
      };
      axios(config).then(res => {
        this.setState({
          phone: values.phone,
          showModalPhone: false
        })
        sessionStorage.setItem("phone", values.phone)
      })
    };
    const onFinishFailed = (errorInfo) => {
    };
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
      autoplay: true
    };
    const { visible, showModalPhone, settings } = this.state;
    return (

      // <div className='conntent_contenn' onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove}>

      //   <div class="swiper-container"  >
      //     <div class="swiper-wrapper">
      //       {
      //         this.state.listarray.map((item, index) => {
      //           return <div class="swiper-slide" key={index}>
      //             <img src={item.background} alt='' Name='contentStyle' style={{ width: '100%', height: "100%" }} onClick={() => {
      //               let itemq = item.link.split("l/")[1]
      //               sessionStorage.setItem('flag', 1)
      //               sessionStorage.setItem('banner', item.link.split("l/")[1])
      //               this.props.history.push({
      //                 pathname: `/detail/${sessionStorage.getItem('banner')}`,
      //                 item: itemq
      //               })
      //             }} />
      //           </div>
      //         })
      //       }
      //     </div>
      //     <div class="swiper-button-prev"></div>
      //     <div class="swiper-button-next"></div>
      //   </div>

      //   <div className='content_xin'>
      //     {sessionStorage.getItem("token") ? "" : <Modal
      //       visible={visible}
      //       closable={true}
      //       centered
      //       footer={null}
      //     >
      //       <img src={weixin} alt='' width='20%' height='20%' onClick={() => { this.towecha() }} /><br></br>
      //       {/* onClick={this.showModal1} */}
      //       <p onClick={() => { this.towecha() }} type="primary1" >点击微信登陆</p>
      //     </Modal>}
      //     <Modal
      //       visible={showModalPhone}
      //       closable={false}
      //       centered={true}
      //       maskClosable={true}
      //       footer={null}
      //     >
      //       <Form
      //         {...layout}
      //         name="basic"
      //         initialValues={{ remember: true }}
      //         onFinish={onFinish}
      //         onFinishFailed={onFinishFailed}
      //       >
      //         <Form.Item
      //           label="手机号"
      //           name="phone"
      //           rules={[{ required: true, message: 'Please input your username!' }]}
      //         >
      //           <Input value={this.state.phoneNum} onChange={(e) => { this.changeNum(e) }} ref='phoneNum' />
      //         </Form.Item>
      //         <Form.Item
      //           label="验证码"
      //           name="password"
      //         >
      //           <Input.Password />
      //         </Form.Item>
      //         <Button onClick={() => { this.getCode() }}>
      //           获取验证码
      //         </Button>
      //         <Form.Item {...tailLayout}>
      //           <Button type="primary" htmlType="submit">
      //             登录
      //           </Button>
      //         </Form.Item>
      //       </Form>
      //     </Modal>
      //     <div className='content_xinyu'>
      //       <div className='cont_no'>
      //         <h3>新游预约</h3>
      //       </div>
      //       <p onClick={() => { window.location.href = "index.html#/shop" }}>MORE</p>
      //     </div>

      //     <div className='content_xibig' ref='xinyuyue' >
      //       <div className='content_xin1'>
      //         {
      //           this.state.newlist.map((item, index) => {
      //             return <div className='contennt_tong' key={index}>
      //               <div className='content_yue'>
      //                 <p className='stateg'>{item.stage === 0 ? '敬请期待' : ''}</p>
      //                 <img src={item.icon} alt='' className='dit_s' onClick={() => { this.yu(item.id) }} />
      //                 <h3 onClick={() => { this.yu(item.id) }}>{item.name}</h3>
      //                 <div className='qian'>
      //                   {
      //                     item.tagList.map((ite, index) => {
      //                       return <div className='content_p' key={index}> <p className='p'>{ite}</p></div>
      //                     })
      //                   }
      //                 </div>
      //                 <Button className='yuye' type="primary" disabled={this.ifArr(item.id)} onClick={() => { this.showModal(item.id) }} >预约</Button>
      //               </div>
      //             </div>
      //           })
      //         }
      //       </div>
      //     </div>
      //   </div>
      //   {
      //     this.state.list.map((item, index) => {
      //       return <div className='content_zong' key={index} v-show=''>

      //         <h2 className='biaot'>{item.name}</h2>
      //         <div className='conntent_big' ref={"scroll" + index} >
      //           <div className='content_lie'>
      //             <div className='content_lie2'>
      //               {
      //                 item.items.map((itemq, index) => {
      //                   return <span className='content_one' key={index}>
      //                     <dl className='content_kng'>
      //                       <div className='dit_clis' key={item.id} onClick={() => this.tiao(itemq)}>
      //                         <img src={itemq.icon} alt='' className='dit_s' />
      //                         <div className='dit_clist'>
      //                           <h3>{itemq.title}</h3>
      //                           <dt className='summary'>{itemq.summary}</dt>
      //                         </div>
      //                       </div>
      //                       <dd className='border_xia' onClick={() => {
      //                         if (itemq.stage === 0) {
      //                           if (sessionStorage.getItem('phone')) {
      //                             if (this.ifArr(itemq.contentId)) {
      //                               message.success("已经预约")
      //                             } else {
      //                               if (sessionStorage.getItem("serviceTokens")) {
      //                                 let data = {
      //                                   openId: sessionStorage.getItem("openId"),
      //                                   platform: 1,
      //                                   mobile: sessionStorage.getItem("phone"),
      //                                   gameId: itemq.contentId
      //                                 }
      //                                 let config = {
      //                                   method: 'post',
      //                                   url: 'https://website-backend.icesimba.com/api/v1/reserve',
      //                                   headers: {
      //                                     'Content-Type': 'application/json',
      //                                     Authorization: sessionStorage.getItem("serviceTokens"),
      //                                   },
      //                                   data: data
      //                                 };
      //                                 axios(config).then(res => {
      //                                 })
      //                                 if (window.sessionStorage.getItem('phone')) {
      //                                   alert('预约成功')
      //                                   window.location.reload()
      //                                 }
      //                               }
      //                             }
      //                           } else {
      //                             this.showModal(itemq.contentId)
      //                           }
      //                         } else {
      //                           if (this._isMobile()) {
      //                             window.location.href = `${itemq.url}`
      //                           } else {
      //                             Modal.info({
      //                               content: (
      //                                 <div >
      //                                   <img src={itemq.qrCode} alt='' className='weer' />
      //                                 </div>
      //                               ),

      //                               onOk() { },
      //                             })
      //                           }
      //                         }
      //                       }}>{itemq.stage === 0 ? "预约" : "下载"}</dd>
      //                     </dl>
      //                   </span>
      //                 })
      //               }
      //             </div>
      //           </div>
      //         </div>
      //       </div>



      //     })
      //   }
      //   <div className='kuang' onClick={() => { window.location.href = "index.html#/shop" }}>查看全部游戏</div>
      // </div>

      <div className='img_center'>
        <img src={mainicon} alt='' width='100%' height='100%' />
      </div>
    );
  }
}
