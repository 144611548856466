
import React, { Component } from 'react';
import axios from 'axios'
import { Timeline ,Modal} from 'antd';
import moment from 'moment'; 
import { CopyToClipboard } from 'react-copy-to-clipboard';
export default class componentName extends Component {
  state={
    listarray:[],
    reverse: false,
  }
  handleClick = () => {
    this.setState({ reverse: !this.state.reverse });
  };

  componentDidMount(){  
    axios.get('https://website-backend.icesimba.com/api/v1/gifts/'+sessionStorage.getItem('game')).then(res=>{
      this.setState({
        listarray:res.data
      })
      console.log(res.data);
      console.log(this.state.listarray);
    })
  }
  render() {   
    return (
      <div>
        <div  className='cont_li'>
        {
        this.state.listarray.map((item,index)=>{
          return <div key={index}>
         
        <Timeline.Item>
           <p style={{fontSize:"25px"}}>
           {
            moment
            (
              item.startTime
            ).
            format
            (
            "YYYY-MM-DD "
            )
            
        }
           </p>
        <div className='cont_toutou'>
          <p className='cont_milin'>活动</p>
          <p className='cont_mihou'>{item.title}</p>
        </div>
         <div className='cont_list'>
           <img src={item.icon} alt='' className='cont_limg'/>
            <div className='cont_qqq'> 
              <p>{item.name}</p>
              
            <p>活动截止日前:{
                
                moment
                (
                  item.endTime
                ).
                format
                (
                "YYYY-MM-DD HH:mm:ss"
                )
                
            }</p>
              
            </div>
            <p className='cont_button' onClick={()=>{
              Modal.info({
                content: (
                  <div >
                   <p>礼包领取码</p>
                   <h2>{item.token}</h2>
                   <CopyToClipboard
                        text={item.token} // 需要复制的文本
                        onCopy={this.handleCopy} // 复制完成的回调
                    >
                        <a href="javascript:;">复制</a>
                    </CopyToClipboard>
                  </div>
                ),
                onOk() {},
              })
            }}>领取</p>
         </div>
        </Timeline.Item>
          
          </div>
        })
      }
        </div>
      
    </div>
    );
  }
}
