
import React, { Component } from 'react';
import { Carousel, Modal, Button, Form, Input, message, Icon } from 'antd';
import axios from 'axios'
import weixin from '../img/weixin.jpeg'
import yybsInfo from '../img/yybsInfo.jpg'
import downloadIcon from '../img/downloadicon.jpg'
let a = 0
export default class componentName extends Component {
  isDebug = true;
  appid = this.isDebug ? 'wx2de61ec075e28685' : 'wx9dc526c2fae22876'; //普通浏览器测试appid（扫码登陆）
  // appid = this.isDebug ? '   ' : 'wx01254fad5fc2b79a'; //普通浏览器测试appid（扫码登陆）

  wechatBrowserAppid = 'wx01254fad5fc2b79a' //微信浏览器中使用appi直接拉起授权

  redirectURI = this.isDebug ? 'http://www.website-client-test.icesimba.com/index.html' : 'http://www.icesimba.com/index.html' //重定向回来的url
  wxState = this.isDebug ? '' : '' //透穿参数

  state = {
    listarray: [],

    list: [],
    newlist: [],
    newList1: [],
    phoneNum: "",
    loading: false,
    visible: false,
    showModalPhone: false,
    wechactlogin: '',
    phone: "",
    flag: false,
    revice: [],
    go: 0,
    settings: {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
  getQueryString(name) {

    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);

    if (r != null) return unescape(r[2]);

    return null;
  }
  showModal = (id) => {
    // sessionStorage.getItem("token")
    console.log(id)

    this.ifArr(id) && message.success("已经预约")
    // window.sessionStorage.reload()
    if (sessionStorage.getItem("serviceTokens")) {
      let data = {
        openId: sessionStorage.getItem("openId"),
        platform: 1,
        mobile: sessionStorage.getItem("phone"),
        gameId: id
      }
      let config = {
        method: 'post',
        url: 'https://website-backend.icesimba.com/api/v1/reserve',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem("serviceTokens"),
        },
        data: data
      };
      axios(config).then(res => {
        console.log(res)
      })
    }

    let data1 = {
      source: "WeChatAccount",
      platformId: this.getQueryString('code'),
      password: "",
      appId: "website-c",
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      data1.appId = 'official-account'
    } else {
      data1.appId = 'website-c'
    }
    let config1 = {
      method: 'get',
      url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + window.sessionStorage.getItem("token")
      },
      data: data1
    };
    axios(config1)
      .then(res => {
        this.setState({
          revice: res,
        })
        console.log(res);
      })


    this.setState({
      visible: true,
      wechactlogin: id,

    });
    if (window.sessionStorage.getItem('phone')) {
      alert('预约成功')
      window.location.reload()
    }
  };
  showModal1 = () => {

    this.setState({
      visible: false,
      showModalPhone: true,
    });
  };

  yu = (itemq) => {
    sessionStorage.setItem('id', itemq)
    console.log(itemq);
    // sessionStorage.setItem('id',this.props.location.item.id)
    this.props.history.push({
      pathname: `/yuyue/${itemq}`,
      item: itemq
    })

  }
  tiao = (item) => {

    console.log(item.stage)
    if (item.stage === 0) {
      sessionStorage.setItem('flag', 1)
      sessionStorage.setItem('banner', item.contentId)
      console.log('yy1')
      this.props.history.push({
        pathname: `/yuyue/${item.contentId}`,
        item: item
      })
    } else if (item.stage === 1) {
      sessionStorage.setItem('flag', 2)
      sessionStorage.setItem('contentId', item.contentId)
      console.log('dt1')
      this.props.history.push({
        pathname: `/detail/${item.contentId}`,
        item: item
      })
    }


  }
  handleClick = () => {

  }
  towecha = () => {
    let isMobile = false;
    let url = '';
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      isMobile = true;
    }



    if (isMobile) {

      var ua = navigator.userAgent.toLowerCase();
      // if(ua.match(/MicroMessenger/i)=="micromessenger") {  //微信浏览器

      url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.wechatBrowserAppid}&redirect_uri=${this.redirectURI}&response_type=code&scope=snsapi_userinfo&state=${this.wxState}#wechat_redirect`
      // }else{
      //   url=`https://open.weixin.qq.com/connect/qrconnect?appid=${this.appid}&redirect_uri=${this.redirectURI}&appointmentId=${this.state.wechactlogin}&response_type=code&scope=snsapi_login&state=${this.wxState}#wechat_redirect`
      // }

      window.location.href = url
    } else {

      url = `https://open.weixin.qq.com/connect/qrconnect?appid=${this.appid}&redirect_uri=${this.redirectURI}&appointmentId=${this.state.wechactlogin}&response_type=code&scope=snsapi_login&state=${this.wxState}#wechat_redirect`
      window.location.href = url
    }
  }
  changeNum = (e) => {
    this.setState({
      phoneNum: e.target.value
    })
  }
  getCode = () => {
    if (this.state.phoneNum) {
      axios.post(`https://simbasdk.icesimba.com/simba-sdk/api/v1/send-verify-code?phoneNumber=${this.state.phoneNum}`).then(res => {
        if (res) {
          console.log(res)
          console.log("发送验证码")
          message.success('验证码发送成功');
        }
      })
    } else {
      message.warning('请输入手机号！');
    }
  }
  ifArr = (id, stage) => {
    let b = this.state.revice.length > 0 && this.state.revice.map(item => {
      if (item.gameId == id) {

        stage = 2
        return true;
      }
      else {
        return false
      }
    })
    if (b.length) {
      return b.includes(true)
    }
  };
  _isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  }
  componentDidMount() {
    let data1 = {
      source: "WeChatAccount",
      platformId: this.getQueryString('code'),
      password: "",
      appId: "website-c",
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      data1.appId = 'official-account'
    } else {
      data1.appId = 'website-c'
    }
    const openId1 = window.sessionStorage.getItem('openId')
    console.log(window.sessionStorage.getItem('openId'));
    let config1 = {
      method: 'get',
      url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + window.sessionStorage.getItem("token")
        // Authorization:'123'
      },
      data: data1
    };
    axios(config1)
      .then(res => {
        this.setState({
          revice: res.data
        })
      })

    if (this.getQueryString('code')) {
      let data = {
        source: "WeChatAccount",
        platformId: this.getQueryString('code'),
        password: "",
        appId: "website-c"
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        data.appId = 'official-account'
      } else {
        data.appId = 'website-c'
      }
      let config = {
        method: 'post',
        url: 'https://simbasdk.icesimba.com/simba-sdk/api/v1/login',
        headers: {
          'Content-Type': 'application/json',
          // Authorization:'Bearer' + ' ' + window.sessionStorage.getItem("token")

        },
        data: data
      };
      axios(config)
        .then(res => {
          console.log(res.data.payload.openId)
          console.log(res.status);
          this.setState({
            go: res.status
          })
          console.log(this.state.go)
          if (res.status === 200) {
            let a = window.sessionStorage.getItem("token")
            this.setState({
              flag: true,
            })

            if (res.data.payload.mobile === "") {
              console.log(res.data)
              sessionStorage.setItem("token", res.data.accessToken)
              sessionStorage.setItem("openId", res.data.payload.openId)

              sessionStorage.setItem("serviceTokens", res.data.serviceTokens["website-backend"])

              this.setState({
                showModalPhone: true
              })
            } else {
              sessionStorage.setItem("token", res.data.accessToken)
              sessionStorage.setItem("openId", res.data.payload.openId)
              sessionStorage.setItem("phone", res.data.payload.mobile)
              sessionStorage.setItem("serviceTokens", res.data.serviceTokens["website-backend"])
            }
            console.log('6666')
            console.log(res.data.payload.openId, '111');

          }
          if (window.sessionStorage.getItem('phone')) {
            window.location.reload()
          }
          console.log(res)
          let data1 = {
            source: "WeChatAccount",
            platformId: this.getQueryString('code'),
            password: "",
            appId: "website-c",
          }
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            data1.appId = 'official-account'
          } else {
            data1.appId = 'website-c'
          }
          const openId1 = window.sessionStorage.getItem('openId')
          console.log(window.sessionStorage.getItem('openId'))
          let config1 = {
            method: 'get',
            url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer' + ' ' + window.sessionStorage.getItem("token")
              // Authorization:'123'
            },
            data: data1
          };
          axios(config1)
            .then(res => {
              console.log(res)
              this.setState({
                revice: res
              })
            })

        })

        .catch(function (error) {
          console.log(error);
        });
    }

    axios.get('https://website-backend.icesimba.com/api/v1/all-game').then(res => {
      this.setState({
        listarray: res.data
      })
      console.log(res.data);
      console.log(this.state.listarray);
    })
  }
  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };


    const onFinish = (values) => {

      let data = {
        openId: sessionStorage.getItem("openId"),
        source: "WeChatAccount",
        phoneNumber: values.phone,
        password: "",
        appId: "",
        payload: ""
      }
      sessionStorage.setItem("password", values.password)
      let config = {
        method: 'post',
        url: `https://simbasdk.icesimba.com/simba-sdk/api/v1/verify-bind-mobile?verifyCode=${values.password}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + ' ' + sessionStorage.getItem("token")

        },

        data: data,
      };
      axios(config).then(res => {
        this.setState({
          phone: values.phone,
          showModalPhone: false
        })
        sessionStorage.setItem("phone", values.phone)
      })
    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
      autoplay: true
    };
    const { visible, showModalPhone, settings } = this.state;
    return (
      //  <div>
      //     {sessionStorage.getItem("token")?"":  <Modal
      //               visible={visible}
      //               closable={false}
      //               centered
      //               footer={null}
      //             >
      //             <img src={weixin} alt=''  width='20%' height='20%'  onClick={()=>{this.towecha()}} /><br></br>
      //             {/* onClick={this.showModal1} */}
      // <p onClick={()=>{this.towecha()}}  type="primary1" >点击微信登陆1</p>
      // </Modal>}
      // <Modal
      //               visible={showModalPhone}
      //               closable={false}
      //               centered={true} 
      //               maskClosable={true}
      //               footer={null}
      //             >
      //               <Form
      //   {...layout}
      //   name="basic"
      //   initialValues={{ remember: true }}
      //   onFinish={onFinish} 
      //   onFinishFailed={onFinishFailed}
      // >
      //   <Form.Item
      //     label="手机号"
      //     name="phone"
      //     rules={[{ required: true, message: 'Please input your username!' }]}
      //   >
      //     <Input value={this.state.phoneNum} onChange={(e)=>{this.changeNum(e)}} ref='phoneNum'/>
      //   </Form.Item>
      //   <Form.Item
      //     label="验证码"
      //     name="password"
      //   >
      //     <Input.Password />

      //   </Form.Item>
      //   <Button   onClick={()=>{this.getCode()}}>
      //       获取验证码
      //     </Button>
      //   <Form.Item {...tailLayout}>
      //     <Button type="primary" htmlType="submit">
      //       登录      
      //     </Button>
      //   </Form.Item>
      // </Form>
      //             </Modal>
      //     <div className='contentStyle'>

      //    </div>

      //    <div className='cont_zong'>
      //      <div className='cont_nr'>
      //        <h1 className='neir'>全部游戏</h1>
      //      </div>
      //    <div className='cont_box'>
      //           {
      //             this.state.listarray.map((item,index)=>{
      //               return <div key={index} className='shop_one'> 
      //                 <div className='shop_one_left'>
      //                 <img src={item.icon} alt='' className='shop_img'  onClick={()=>this.tiao(item)}/>
      //                 <p>{item.title}</p>
      //                 </div>
      //                 <p className='shop_xiaz' onClick={()=>{
      //                                                   if(item.stage===0)
      //                                                   { 
      //                                                     if(sessionStorage.getItem('phone')){
      //                                                       if( this.ifArr(item.contentId,item.stage)){
      //                                                         message.success("已经预约")
      //                                                       }else{       
      //                                                         if(sessionStorage.getItem("serviceTokens"))
      //                                                         {
      //                                                           let data = {
      //                                                           openId:sessionStorage.getItem("openId"),
      //                                                           platform:1,
      //                                                           mobile:sessionStorage.getItem("phone"),
      //                                                           gameId:item.contentId
      //                                                         }
      //                                                         let config = {
      //                                                           method: 'post',
      //                                                           url: 'https://website-backend.icesimba.com/api/v1/reserve',
      //                                                           headers: { 
      //                                                             'Content-Type': 'application/json',
      //                                                             Authorization:sessionStorage.getItem("serviceTokens"),
      //                                                           },  
      //                                                           data : data
      //                                                         };
      //                                                         axios(config).then(res=>{
      //                                                           console.log(res)
      //                                                         })

      //                                                           if(window.sessionStorage.getItem('phone')){
      //                                                             alert('预约成功')
      //                                                             window.location.reload()
      //                                                         }
      //                                                          }
      //                                                       }
      //                                                     }else{
      //                                                      this.showModal(item.contentId)
      //                                                     }
      //                                                   }else {
      //                                                     if (this._isMobile()) {
      //                                                       window.location.href=`${item.url}`
      //                                                     } else {
      //                                                       Modal.info({
      //                                                         content: (
      //                                                           <div>
      //                                                             <img src={item.qrCode} alt='' />
      //                                                           </div>
      //                                                         ),
      //                                                         onOk(){},
      //                                                       });
      //                                                     }
      //                                                   }
      //                                               }}>{

      //                                                 item.stage===0?"预约":'下载'
      //                                               }</p>
      //               </div>
      //             })
      //           }
      //     </div>
      //     </div>
      //  </div>
      <div className='img_center'>
        <img src={yybsInfo} alt='' width='100%' height='100%' />
      </div>
    );
  }
}
