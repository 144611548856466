import Home from '../View/Home'
import List from '../View/List'
import Shop from '../View/Shop'
import Detail from '../View/Detail'
import ListB from '../View/ListB'
import Yuyue from '../View/yuyue'
import Gamelist from '../View/Gamelist'
import Bug from '../View/Bug'

const data = [
    {
        path:'/home',
        component:Home
    },
    {
        path:'/list',
        component:List
    },

    {
        path:'/bug',
        component:Bug
    },
    {
        path:'/gamelist',
        component:Gamelist
    },
    {
        path:'/yuyue/:id',
        component:Yuyue
    },
    {
        path:'/shop',
        component:Shop
    },
    {
        path:'/detail/:id',
        component:Detail
    },
    {
        path:'/listb/:id',
        component:ListB
    },
    {
        to:'/home',
        from:'/'
    },
]
export default data