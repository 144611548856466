import React, { Component } from 'react';
import RouteView from './Route/RouteView';
import routes from './Route/Routes';
import { NavLink, withRouter } from 'react-router-dom';
import Footer from './View/Fotter'
import './App.css'
import { connect } from 'react-redux'
import { Affix, } from 'antd';
import axios from 'axios'
// import img from './img/img2.jpg'
class App extends Component {
  state = {
    listarray: [],
    AllGameList: []
  }

  async searchFunc(v) {
    if (v.keyCode === 13) {
      console.log(1111)
      this.props.history.push({
        pathname: "/gamelist",
        state: {
          value: v.target.value
        }
      })

    } else {
      axios.get("https://website-backend.icesimba.com/api/v1/all-game").then(res => {
        this.setState({
          AllGameList: res.data,

        })

      })
    }
  }
  sou = (v) => {
    console.log(111)
    this.props.history.push({
      pathname: "/gamelist",
      state: {
        value: v
      }
    })

  }
  componentDidMount() {
    console.log([
      "%c                   _ooOoo_",
      "                  o8888888o",
      "                  88\" . \"88",
      "                  (| -_- |)",
      "                  O\\  =  /O",
      "               ____/`---'\\____",
      "             .'  \\\\|     |//  `.",
      "            /  \\\\|||  :  |||//  \\",
      "           /  _||||| -:- |||||-  \\",
      "           |   | \\\\\\  -  /// |   |",
      "           | \\_|  ''\\---/''  |   |",
      "           \\  .-\\__  `-`  ___/-. /",
      "         ___`. .'  /--.--\\  `. . __",
      "      .\"\" '<  `.___\\_<|>_/___.'  >'\"\".",
      "     | | :  `- \\`.;`\\ _ /`;.`/ - ` : | |",
      "     \\  \\ `-.   \\_ __\\ /__ _/   .-` /  /",
      "======`-.____`-.___\\_____/___.-`____.-'======",
      "                   `=---='",
      "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^",
    ].join('\n'), 'color: #fad144;');
    console.log('%c     佛祖保佑   T_T    永无BUG', 'color: #fa4844;font-size: 16px;font-weight: bold');
    axios.get('https://website-backend.icesimba.com/api/v1/all-game').then(res => {
      this.setState({
        listarray: res.data
      })
    })
  }
  render() {
    return (
      <div className='app'>
        {/* <Affix className='maoding'>
          <div className='header'>
            <div className='headerT'>
              <div className='header_left'>
                <div onClick={() => { window.location.href = "/index.html#/home" }}><img src={img} alt='' style={{ width: '20%', height: '20%', marginTop: '1rem' }} /></div>
              </div>
              <div className='header_right'>
                <NavLink to='/home' className='content_right'>首页</NavLink>
                <NavLink to='/shop' className='content_right'>游戏库</NavLink>
                <NavLink to='/list' className='content_right'>礼包</NavLink>
              </div>
              <input placeholder='请输入查找的游戏' ref='qwer' onKeyDown={(v) => this.searchFunc(v)} /><button className='button_sou' onClick={() => this.sou(this.refs.qwer.value)} >搜索</button>
            </div>
          </div>
        </Affix> */}
{/* 
        <div className='header'>
          <div className='header_right'>
            <NavLink to='/home' className='content_right'>首页</NavLink>
            <NavLink to='/shop' className='content_right'>月影别墅</NavLink>
          </div>
        </div> */}
        <RouteView routes={routes} />
        <Footer />
      </div>
    );
  }
}
function statePropsA(state) {
  return state
}
function statePropsB(dispatch) {
  return
}
export default withRouter(connect(statePropsA, statePropsB)(App))
