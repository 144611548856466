import React, { Component } from 'react';
import axios from 'axios'
import './game.css'
export default class componentName extends Component {
  state={
    list:[],
    value:"",
    AllGameList:[]
  }
 componentDidMount(){
  axios.get("https://website-backend.icesimba.com/api/v1/all-game").then(res=>{
    console.log(this.props.history.location.state.value)
    console.log(this.v)
     let name=this.props.history.location.state.value
    this.setState({
      AllGameList:res.data,
      value:this.props.history.location.state.value,    
      list:res.data.filter(item=>item.title.includes(name))
    })
   })
     setTimeout(() => {
       console.log(this.state.list)
     }, 1000)
 }
  render() {  
    return (
      <div className='gamezong'>
        <div className='gametou'>
          <div className='gametou2'>
          <h1>{this.state.value}</h1> 
          <p>找到{this.state.list.length}条相关结果</p>
          </div>
        </div>
        <div className='gamezz'></div>
        <div className='gamecontent'> 
          {this.state.list.map((item,index)=>{            
            return <div key={index} className='gamecontent1'>
              {console.log(item)}
              <a className='gamecontett' onClick={()=>{
                item.stage === 0?this.props.history.push({
                  pathname:`/yuyue/${item.contentId}`,                                                                                                                                                                                                                                                                                                                                                                                                        
                  item:item 
                },sessionStorage.setItem('flag',1), sessionStorage.setItem('banner',item.contentId)) : this.props.history.push({
                  pathname:`/detail/${item.contentId}`,
                  item:item
                }, sessionStorage.setItem('flag',2),sessionStorage.setItem('contentId',item.contentId))
              }}>
              <img src={item.icon} alt='' className='yyimg'/>
              <p className='gamelis'>{item.title}</p>
              </a>
            </div>    
          })}
        </div>  
         </div>
    );
  }
}
