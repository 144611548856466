
import React, { Component } from 'react';
import './Detail.css'
import axios from 'axios'
import BScroll from '@better-scroll/core'
import { Tabs } from 'antd';
import { Image } from 'antd'
import { Modal} from 'antd';


const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
export default class componentName extends Component {
  state={
    detailist:[],
    listdata:[],
    featureList:[], 
    categorys:[],
    pictu:''
  }
  getQueryString(){  
    let r = window.location.href.split("/")
    return r[4];               
}
  componentDidMount(){  
  
  setTimeout(() => {  
    this.bs =new BScroll('.detail_big',{
      scrollX:true,
      click:true,
    })
  }, 1200);
 
  if(this.getQueryString()==='undefined'){
    console.log(3)
    axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('banner')).then(res=>{
      this.setState({
        detailist:res.data,
        listdata:res.data.links,
        featureList:res.data.featureList,
        categorys:res.data.categorys,
        pictu:res.data.pictureScale
      })  
    })
    console.log(3)
  } 

  // else 
  // {
  //   console.log(2)
  //   axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('banner')).then(res=>{
  //     this.setState({
  //       detailist:res.data,
  //       listdata:res.data.links,
  //       featureList:res.data.featureList,
  //       categorys:res.data.categorys,
  //       pictu:res.data.pictureScale
  //     })  
  //   })
  // }
   if(sessionStorage.getItem('flag')==='1'){
    console.log(sessionStorage.getItem('flag'))
    console.log(2)
    axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('banner')).then(res=>{
      this.setState({
        detailist:res.data,
        listdata:res.data.links,
        featureList:res.data.featureList,
        categorys:res.data.categorys,
        pictu:res.data.pictureScale
      })  
    })
  }
 
  if(sessionStorage.getItem('flag')==='2'){
      console.log(sessionStorage.getItem('flag'))
      console.log(1)
       axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('contentId')).then(res=>{
        this.setState({
          detailist:res.data,
          listdata:res.data.links,
          featureList:res.data.featureList,
          categorys:res.data.categorys,
          pictu:res.data.pictureScale
        })  
      })
    
  }
  
  }
  _isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  }
  detailxx=(item)=>{
      console.log(111)
      if (this._isMobile()) {
        console.log(222)
       window.location.href=`${item.url}`
     } else {
       console.log(333)
       Modal.info({
         content: (
           <div>
             <img src={item.qrCode} alt='' className='weer' />
           </div>
         ),
         onOk() {},
       })
     }
  }
  render() {
    return (
    <div className='detail_content'>
       <div className='detail_zong'> 
       <div style={{background:`url('${this.state.detailist.background}')`}} >
           <div className='detail_tou' style={{width:'100%',transform:'scale(1)'}} >
             <div className='detail_tou1'>
               <img src={this.state.detailist.icon} alt='' className='dit_s' />
               <div >
                   <h2>{this.state.detailist.name} </h2>
                   <p>{this.state.detailist.summary}</p>
               </div>
             </div>
             <div className='detail_ab' onClick={()=>{this.detailxx(this.state.detailist)}}>下载游戏</div>
           </div>
           </div>
           <div className='detail_nr'>
               <div className='detail_ne1'>   
                 <p className='detail_br'>
                   {this.state.detailist.description}
                 </p> 
               </div>
           </div>
           <div className='detail_gw'>
             <div className='detail_gw1'>
               <div className='detail_gw2'>
                 <div className='detail_gw3'>
                     <div className='detail_gw4'>
                       <span className='detail_span'>{this.state.listdata.map((item,index)=>{
                        
                         return <div key={index} className='detail_a'>
                           <img src={item.icon} alt='' onClick={()=>{
                             console.log(1111)
                              if(item.type===1){
                                console.log(222)
                                Modal.info({
                                  content: (
                                    <div >
                                      <img src={item.qrcode} alt='' className='weer'  />
                                    </div>
                                  ),
                                 
                                })
                              }else{
                                console.log(333)
                                window.location.href = `https://${item.url}`
                              }


                           }} />
                         
                         </div> 
                       })}</span>
                     </div>
                 </div>
               </div>
             </div>
           </div>
            <div className='detail_featureList'>
              <p className='featureList_p'>游戏特色</p>
              <div className='features_img'>
                <div className='detail_big' >
               <div className='detail_pig'>
               {
                  this.state.featureList.map((item,index)=>{
                    
                    return <div className='swiper-wrapper' key={index}>
                        <Image  className={ this.state.pictu===1 ? "gheng" : "gshu"} src={item} alt=''  preview='查看图片' />
                    </div>
                  })
                }
               </div>
                </div>
              </div>
            </div>
            <div className='detail_categorys'>
            <Tabs defaultActiveKey="1" onChange={callback} >
              {
                this.state.categorys.map((item,index)=>{
                  
                  return  <TabPane tab={item.name} key={index} >
                          {
                           item.items.map((itemss,inndex)=>{
                             return <p key={inndex}>{itemss.title}</p>
                           })
                          }
                          </TabPane>
                })
              }
          </Tabs>
            </div>
       </div> 
    </div>
    );
  }
}