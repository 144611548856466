
import React, { Component } from 'react';
import './Detail.css'
import axios from 'axios'
import BScroll from '@better-scroll/core'
import { Tabs } from 'antd';
import { Image } from 'antd'
import { Carousel ,Modal, Button,Form, Input , message} from 'antd';import weixin from '../img/weixin.jpeg'
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);       
}  


 
export default class componentName extends Component {
  isDebug = true;
  appid = this.isDebug ? 'wx2de61ec075e28685' : 'wx9dc526c2fae22876'; //普通浏览器测试appid（扫码登陆）
  // appid = this.isDebug ? '   ' : 'wx01254fad5fc2b79a'; //普通浏览器测试appid（扫码登陆）
  
  wechatBrowserAppid = 'wx01254fad5fc2b79a' //微信浏览器中使用appi直接拉起授权

  redirectURI = this.isDebug ? 'http://www.website-client-test.icesimba.com/index.html' : 'http://www.icesimba.com/index.html' //重定向回来的url
  wxState = this.isDebug ? '' : '' //透穿参数
  state={
    detailist:[],
    listdata:[],
    featureList:[], 
    categorys:[],
    listarray:[],
    list:[],
    newlist:[],
    newList1:[],
    phoneNum:"",
    loading: false,
    visible: false,
    showModalPhone: false,
    wechactlogin:'',
    phone:"",
    pictu:'',
    flag:false,
    revice:[],
    go:0,
    settings : {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
  getQueryString(name){  

    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
    let r = window.location.search.substr(1).match(reg); 

    if (r != null) return unescape(r[2]); 
    
    return null;              
}
  showModal = (id) => {
    // sessionStorage.getItem("token")
    
   
    this.ifArr(id)&&message.success("已经预约")
    // window.sessionStorage.reload()
    if(sessionStorage.getItem("serviceTokens"))
    {
      let data = {
        openId:sessionStorage.getItem("openId"),
        platform:1,
        mobile:sessionStorage.getItem("phone"),
        gameId:id
      }
      let config = {
        method: 'post',
        url: 'https://website-backend.icesimba.com/api/v1/reserve',
        headers: { 
          'Content-Type': 'application/json',
          Authorization:sessionStorage.getItem("serviceTokens"),
        },  
        data : data
      };
      axios(config).then(res=>{
      })
    }

    let data1 = {
     source:"WeChatAccount", 
     platformId:this.getQueryString('code'),
     password:"",
     appId:'',
   }
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      data1.appId = 'official-account'
    }else {
      data1.appId = 'website-c'
    }
  
   let config1 = {
     method: 'get',
     url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`, 
     headers: { 
       'Content-Type': 'application/json',
       Authorization:'Bearer' + ' ' + window.sessionStorage.getItem("token")     
     },
     data : data1
   };
   axios(config1)
     .then(res=>{
       this.setState({
         revice:res,
       })
       console.log(res);
     })
    this.setState({
      visible: true,
      wechactlogin:id,
      
    });
    if(window.sessionStorage.getItem('phone')){
      alert('预约成功')
      window.location.reload()
  }
  };
  showModal1 = () => {
      
    this.setState({
      visible: false,
      showModalPhone: true,
    });
  };

  getQueryString(){  
    let r = window.location.href.split("/")
    console.log();
    return r[4];     
            
}
towecha=()=>{
  let isMobile =  false ;
  let url='';
if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
　　isMobile =  true ;
}



if (isMobile){

var ua = navigator.userAgent.toLowerCase();
// if(ua.match(/MicroMessenger/i)=="micromessenger") {  //微信浏览器
  url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.wechatBrowserAppid}&redirect_uri=${this.redirectURI}&response_type=code&scope=snsapi_userinfo&state=${this.wxState}#wechat_redirect`
// }else{
//   url=`https://open.weixin.qq.com/connect/qrconnect?appid=${this.appid}&redirect_uri=${this.redirectURI}&appointmentId=${this.state.wechactlogin}&response_type=code&scope=snsapi_login&state=${this.wxState}#wechat_redirect`
// }

window.location.href=url
}else{
url=`https://open.weixin.qq.com/connect/qrconnect?appid=${this.appid}&redirect_uri=${this.redirectURI}&appointmentId=${this.state.wechactlogin}&response_type=code&scope=snsapi_login&state=${this.wxState}#wechat_redirect`
window.location.href=url
}
}
changeNum=(e)=>{
  this.setState({
    phoneNum:e.target.value
  })
}
getCode=()=>{
  if(this.state.phoneNum){
    axios.post(`https://simbasdk.icesimba.com/simba-sdk/api/v1/send-verify-code?phoneNumber=${this.state.phoneNum}`).then(res=>{
    if(res){
      message.success('验证码发送成功');
    }
})
  }else{
    message.warning('请输入手机号！');
  }
}
ifArr=(id)=> {
      
  let b=this.state.revice.length>0&&this.state.revice.map(item=>{
    if(item.gameId==id)
    {
      
     return true;
    }
    else{
      return false
    }
  })
  if(b.length)
  {
    return b.includes(true)
  }
};
_isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}
  componentDidMount(){  
   console.log(this.props.location.item);
   sessionStorage.setItem('contentid',this.props.location.item)
  setTimeout(() => {  
    this.bs =new BScroll('.detail_big',{
      scrollX:true,
      click:true, 
    })
  }, 1200);
  if(sessionStorage.getItem('flag')==='1'){
    console.log(sessionStorage.getItem('flag'))
    console.log(2)
    axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('banner')).then(res=>{
      this.setState({
        detailist:res.data,
        listdata:res.data.links,
        featureList:res.data.featureList,
        categorys:res.data.categorys,
        pictu:res.data.pictureScale
      })  
    })
  }
  if(sessionStorage.getItem('flag')==='3'){
    console.log(sessionStorage.getItem('flag'))
    console.log(2)
    axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('contentId')).then(res=>{
      this.setState({
        detailist:res.data,
        listdata:res.data.links,
        featureList:res.data.featureList,
        categorys:res.data.categorys, 
        pictu:res.data.pictureScale
      })  
    })
  }

  if(sessionStorage.getItem('flag')==='4'){
      axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+sessionStorage.getItem('id')).then(res=>{
        this.setState({
          detailist:res.data,
          listdata:res.data.links,
          featureList:res.data.featureList,
          categorys:res.data.categorys,
          pictu:res.data.pictureScale
        })  
      })
     
    }else{
      axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+this.getQueryString()).then(res=>{
        this.setState({
          detailist:res.data,
          listdata:res.data.links,
          featureList:res.data.featureList,
          categorys:res.data.categorys,
          pictu:res.data.pictureScale
        })  
      })
    }
    console.log(this.getQueryString(),'666')
    let data1 = {
      source:"WeChatAccount",
      platformId:this.getQueryString('code'),
      password:"",
      appId:"website-c",
    }
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      data1.appId = 'official-account'
    }else {
      data1.appId = 'website-c'
    }
    const openId1= window.sessionStorage.getItem('openId')
    let config1 = {
      method: 'get',
      url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`, 
      headers: { 
        'Content-Type': 'application/json',
        Authorization:'Bearer' + ' ' + window.sessionStorage.getItem("token")     
        // Authorization:'123'
      },
      data : data1
    };
    axios(config1)
      .then(res=>{
        this.setState({ 
          revice:res.data
        })
      })

    if(this.getQueryString('code')){
          let data = {
            source:"WeChatAccount",
            platformId:this.getQueryString('code'),
            password:"",
            appId:"website-c"
          }
          if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            data.appId = 'official-account'
          }else {
            data.appId = 'website-c'
          }
          let config = {
            method: 'post',
            url: 'https://simbasdk.icesimba.com/simba-sdk/api/v1/login', 
            headers: { 
              'Content-Type': 'application/json',
              // Authorization:'Bearer' + ' ' + window.sessionStorage.getItem("token")
         
            },
            data : data
          };
          axios(config)
      .then( res=>{
        this.setState({
          go:res.status
        })
         if(res.status===200){
           let a = window.sessionStorage.getItem("token")
            this.setState({
              flag:true,
            })
          
          if(res.data.payload.mobile === ""){
            sessionStorage.setItem("token",res.data.accessToken)
            sessionStorage.setItem("openId",res.data.payload.openId)
            
          sessionStorage.setItem("serviceTokens",res.data.serviceTokens["website-backend"])

            this.setState({
              showModalPhone:true 
            })     
          } else{
            sessionStorage.setItem("token",res.data.accessToken)
            sessionStorage.setItem("openId",res.data.payload.openId)
            sessionStorage.setItem("phone",res.data.payload.mobile)
            sessionStorage.setItem("serviceTokens",res.data.serviceTokens["website-backend"])
          }
        }
        if(window.sessionStorage.getItem('phone')){
          window.location.reload()
      }
       let data1 = {
        source:"WeChatAccount",
        platformId:this.getQueryString('code'),
        password:"",
        appId:"website-c",
      }
      if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        data1.appId = 'official-account'
      }else {
        data1.appId = 'website-c'
      }
      const openId1= window.sessionStorage.getItem('openId')
      let config1 = {
        method: 'get',
        url: `https://website-backend.icesimba.com/api/v1/reserve?openid=${window.sessionStorage.getItem('openId')}`, 
        headers: { 
          'Content-Type': 'application/json',
          Authorization:'Bearer' + ' ' + window.sessionStorage.getItem("token")     
          // Authorization:'123'
        },
        data : data1
      };
      axios(config1)
        .then(res=>{
          this.setState({
            revice:res
          })
        })
      
      })
      
      .catch(function (error) {
      });   
  

}
    // if(this.getQueryString()!=='undefined'){
    //   axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+this.getQueryString()).then(res=>{
    //     this.setState({
    //       detailist:res.data,
    //       listdata:res.data.links,
    //       featureList:res.data.featureList,
    //       categorys:res.data.categorys
    //     })  
    //   })
    // }else{
    //   axios.get('https://website-backend.icesimba.com/api/v1/game-page/'+this.props.location.item).then(res=>{
    //     this.setState({
    //       detailist:res.data,
    //       listdata:res.data.links,
    //       featureList:res.data.featureList,
    //       categorys:res.data.categorys
    //     })  
    //   })
    // }
  }
  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    
      const onFinish = (values) => {
       
        let data = {
            openId:sessionStorage.getItem("openId"),
            source:"WeChatAccount",
            phoneNumber:values.phone,
            password:"",
            appId:"",
            payload:""
        }
        sessionStorage.setItem("password",values.password)
        let config = {
          method: 'post',
          url: `https://simbasdk.icesimba.com/simba-sdk/api/v1/verify-bind-mobile?verifyCode=${values.password}`,
          headers: { 
            'Content-Type': 'application/json',
            Authorization:'Bearer' + ' ' + sessionStorage.getItem("token")
            
          },
          
          data : data,
        };
        axios(config).then(res=>{
          this.setState({
            phone:values.phone,
            showModalPhone:false
          })
          sessionStorage.setItem("phone",values.phone)
        })                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
    };
    
      const onFinishFailed = (errorInfo) => {
      };
      const lunboSetting = {
        dots: true,
        lazyLoad: true,
        autoplay:true
       };
    const { visible, showModalPhone,settings} = this.state;

    return (
     
    <div className='detail_content'>
      {sessionStorage.getItem("token")?"":  <Modal
                  visible={visible}
                  closable={false}
                  centered
                  footer={null}
                  
                >
                <img src={weixin} alt=''  width='20%' height='20%'  onClick={()=>{this.towecha()}} /><br></br>
                {/* onClick={this.showModal1} */}
    <p onClick={()=>{this.towecha()}}  type="primary1" >点击微信登陆</p>
    </Modal>}
            <Modal
                          visible={showModalPhone}
                          closable={false}
                          centered={true} 
                          maskClosable={true}
                          footer={null}
                        >
                          <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish} 
              onFinishFailed={onFinishFailed}
            >
                        <Form.Item
                          label="手机号"
                          name="phone"
                          rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                          <Input value={this.state.phoneNum} onChange={(e)=>{this.changeNum(e)}} ref='phoneNum'/>
                        </Form.Item>
                        <Form.Item
                          label="验证码"
                          name="password"
                        >
                          <Input.Password />
                        
                        </Form.Item>
                        <Button   onClick={()=>{this.getCode()}}>
                            获取验证码
                          </Button>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            登录      
                          </Button>
                        </Form.Item>
                      </Form>
              </Modal>
       <div className='detail_zong'> 
       <div style={{width:'100%',transform: 'scale(1)'}}>
                 <div className='detail_header'  style={{ background:`url('${this.state.detailist.background}')`}}> </div>
 
           <div className='detail_tou'>
             <div className='detail_tou1'>  
               
               <img src={this.state.detailist.icon} alt='' className='dit_s' />
                
               <div>
                   <h2>{this.state.detailist.name} </h2>
                   <p>{this.state.detailist.summary}</p>
                   <p style={{display:'flex'}}>预约数:<p style={{color:'white'}}>{this.state.detailist.reservationNumber}</p></p>
               </div>
             </div>
             <div className='detail_ab'  disabled={this.ifArr(this.state.detailist.id)}  onClick={()=>{
               if(sessionStorage.getItem('phone')){
                if( this.ifArr(this.state.detailist.id)){
                  message.success("已经预约")
                }else{
                  if(sessionStorage.getItem("serviceTokens"))
                  {
                    let data = {
                    openId:sessionStorage.getItem("openId"),
                    platform:1,
                    mobile:sessionStorage.getItem("phone"),
                    gameId:this.state.detailist.id
                  }
                  let config = {
                    method: 'post',
                    url: 'https://website-backend.icesimba.com/api/v1/reserve',
                    headers: { 
                      'Content-Type': 'application/json',
                      Authorization:sessionStorage.getItem("serviceTokens"),
                    },
                    data : data
                  };
                  axios(config).then(res=>{
                  })
                    if(window.sessionStorage.getItem('phone')){
                    alert('预约成功')
                    window.location.reload()  
                  }
                  }
                }}else{
                  this.showModal(this.state.detailist.contentId)
                
                 }
             }}>预约游戏</div>
          
           </div>
           </div>
           <div className='detail_nr'>
               <div className='detail_ne1'>
                 <p className='detail_br'>
                   {this.state.detailist.description}
                 </p>
               </div>
           </div>
           <div className='detail_gw'>
             <div className='detail_gw1'>
               <div className='detail_gw2'>
                 <div className='detail_gw3'>
                     <div className='detail_gw4'>
                       <span className='detail_span'>{this.state.listdata.map((item,index)=>{
                         return <div key={index} className='detail_a'>
                           <img src={item.icon} alt='' onClick={()=>{
                             console.log('111')
                              if(item.type===1){
                                console.log(222)
                                Modal.info({
                                  content: (
                                    <div >
                                      <img src={item.qrcode} alt='' className='weer'  />
                                    </div>
                                  ),
                                 
                                })
                              }else{
                                console.log(333)
                                window.location.href = `https://${item.url}`
                              }


                           }} />
                          
                         </div> 
                       })}</span>
                     </div>
                 </div>
               </div>
             </div>
           </div>
            <div className='detail_featureList'>
              <p className='featureList_p'>游戏特色</p>
              <div className='features_img'>
                <div className='detail_big' >
               <div className='detail_pig'>
               {
                  this.state.featureList.map((item,index)=>{
                    return <div className='swiper-wrapper' key={index}>
                        <Image src={item} alt=''  className={ this.state.pictu===1 ? "gheng" : "gshu"} preview='查看图片' />
                    </div>
                  })
                }
               </div>
                </div>
              </div>
            </div>
            <div className='detail_categorys'>
            <Tabs defaultActiveKey="1" onChange={callback} >
              {
                this.state.categorys.map((item,index)=>{
                  return  <TabPane tab={item.name} key={index} >
                          {
                           item.items.map((itemss,inndex)=>{
                             return <p key={inndex}>{itemss.title}</p>
                           })
                          }
                          </TabPane>
                })
              }
          </Tabs>
            </div>
       </div> 
    </div>
    );
  }
}