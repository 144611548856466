
import React, { Component } from 'react';
import axios from 'axios'
import erwei from '../img/erwei.jpg'
import { Modal } from 'antd';
export default class componentName extends Component {
  state = {
    listarray: [],
  }
  lb = (item) => {
    sessionStorage.setItem('game', item.gameId)
    this.props.history.push({

      pathname: `/listb/${item.gameId}`,
      item: item
    })
  }
  componentDidMount() {

    axios.get('https://website-backend.icesimba.com/api/v1/gifts').then(res => {
      this.setState({
        listarray: res.data
      })
      console.log(res.data);
      console.log(this.state.listarray);
    })
  }
  render() {
    return (
      <div>
        <div className='contentStyle'>
        </div>
        <div className='cont_zong'>
          <div className='cont_nr'>
            <h1 className='neir'>全部礼包</h1>
          </div>
          <div className='cont_box'>
            {
              this.state.listarray.map((item, index) => {
                return <div key={index} className='shop_one'>
                  <div className='shop_one_left' >
                    <img src={item.icon} alt='' className='shop_img' onClick={() => { this.lb(item) }} />
                    <div>
                      <p >{item.name}</p><br></br>
                      <p>礼包数量:{item.number}</p>
                    </div>
                  </div>
                  <p className='shop_xiaz' onClick={() => {
                    Modal.info({
                      content: (
                        <div >
                          <img src={erwei} alt='' className='weer' />
                        </div>
                      ),

                      onOk() { },
                    })
                  }}>订阅</p>
                </div>
              })
            }
          </div>
        </div>
      </div>
    );
  }
}