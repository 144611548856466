import {createStore} from 'redux'

const list={

}

const reducer = (state=list,action)=>{
    let NewList = JSON.parse(JSON.stringify(action))
    switch (action.type) {
        case '':
            
            return NewList;
    
        default:
            return NewList;
    }
}

const store = createStore(reducer)

export default store