import React from 'react';
import {Switch,Redirect,Route} from 'react-router-dom';
export default function componentName(props) {
  let routeList = props.routes.filter((item)=>{
    return item.component
  })
  let redurectList = props.routes.filter((item)=>{
    return !item.component
  })
  return (
    <Switch>
      {
        routeList.map((item,index)=>{
          return <Route key={index} path={item.path} render={(stateProps)=>{
           return <item.component {...stateProps} routes={item.children} />
          }} />
        })
      }
      {
        redurectList.map((item,index)=>{
          return <Redirect to={item.to} from={item.from} key={index} />
        })
      }
    </Switch>
  );
}
