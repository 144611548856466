import React, { Component } from 'react';
import img from '../img/img.png'
export default class componentName extends Component {
  render() {
    return (
      <div className='footer'>
          <div className='footer_cont'>
              <div className='footer_top' >
                <img src={img} alt='' style={{width:'10%'}} />
                {/* <div className='footer_right'>
                    <a className='nr' href='https://icesimbasdkweb.icesimba.com/privacy.html'>
                    隐私政策
                    </a>
                 
                </div> */}
              </div>  
              <div className='footer_bottom' style={{"text-align":"center"}}>
                <p className='bnr' >       
                      网站备案/许可证号：<a href='https://beian.miit.gov.cn/#/Integrated/index'>京ICP备2021032690号-1 </a><br></br>
                      网络文化经营许可证编号：<a href='http://simbahd.com/wangwen.png'>京网文〔2022)1815-053号</a><br></br>
                      北京冰狮互动科技有限公司 © 版权所有 互联网违法不良信息举报电话：4006638866
                </p>
               </div>
          </div>
      </div>
    );
  }
}
